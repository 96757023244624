import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const AdminDashboardModule = new Module({
  id: 'admin-dashboard',
  name: 'Dashboard',
  label: 'Dashboard',
  path: 'dashboard',
  Component: ComponentLoader(
    lazy(() => import('src/content/dashboards/AIS140'))
  )
});

export default new ModuleBundle('Admin Dashboard', [AdminDashboardModule]);
