import { Fragment, forwardRef, useMemo, useState } from 'react';
import {
  Link,
  Box,
  Divider,
  IconButton,
  List,
  CircularProgress,
  ListItem,
  Grid,
  InputBase,
  Tooltip,
  Typography,
  Card,
  Dialog,
  alpha,
  Slide,
  styled,
  useTheme,
  Button,
  Chip,
  Stack
} from '@mui/material';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { ActivationViewModule } from 'src/content/root/Activation/module';
import { VehicleStatusViewDashboardModule } from 'src/content/dashboards/VehicleStatus/module';
import { DashboardViewModule } from 'src/content/dashboards/sdDashboard/module';
import { DeviceViewModule } from 'src/content/management/Devices/module';
import { VehicleViewModule } from 'src/content/management/Vehicles/module';
import { UserViewModule } from 'src/content/management/Users/module';
import ErpService from 'src/services/Erp.service';
import toast from 'react-hot-toast';
import UserResults from './UserResults';
import DeviceResults from './DeviceResults';
import VehicleResults from './VehicleResults';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const ListButton = styled(Box)(
  ({ theme }) => `
      background-color: transparent;
      color:  ${theme.colors.alpha.black[100]};
      transition: ${theme.transitions.create(['all'])};
      border: ${theme.colors.alpha.black[10]} solid 1px;
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin: ${theme.spacing(1, 0)};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div > .MuiSvgIcon-root {
        color:  ${theme.colors.alpha.black[50]};
        transition: ${theme.transitions.create(['all'])};
      }

      &:hover {
        background-color: ${alpha(theme.colors.primary.main, 0.08)};
        color:  ${theme.colors.primary.main};
        border-color: ${alpha(theme.colors.primary.main, 0.3)};

        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.primary.main};
        }
      }
`
);

function HeaderSearch() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [inFeature, setInFeature] = useState(null);

  const features = useMemo(() => ['Vehicles', 'Devices', 'Users'], []);

  const submitSearch = async (event) => {
    event.preventDefault();
    try {
      setSearchResults(false);
      setSearchLoading(true);
      const { data: results } = await ErpService.getSearchResults(
        searchValue,
        inFeature
      );
      setSearchResults(results);
    } catch (err) {
      toast.error(err.message || 'Something went wrong');
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSearchChange = async (event) => {
    event.preventDefault();

    const searchTerm = event?.target?.value;

    if (!searchTerm) {
      setSearchValue('');
      setSearchResults(null);
    }

    setSearchResults(null);
    setSearchValue(event.target.value);
    // setSearchLoading(true);
    // await wait(1500);
    // setSearchLoading(false);
    // setSearchResults(true);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Search')}>
        <Button
          onClick={handleClickOpen}
          color="primary"
          variant="outlined"
          startIcon={<SearchTwoToneIcon />}
        >
          <Typography variant="h5" px={4}>
            Search
          </Typography>
        </Button>

        {/* <IconButtonWrapper color="primary" onClick={handleClickOpen}>
         
        </IconButtonWrapper> */}
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <Box>
          <form onSubmit={submitSearch}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center">
                <SearchTwoToneIcon
                  sx={{
                    ml: 2,
                    color: theme.colors.secondary.main
                  }}
                />
                <SearchInputWrapper
                  value={searchValue}
                  onChange={handleSearchChange}
                  autoFocus
                  placeholder={t('Search terms here...')}
                  fullWidth
                />
                <IconButton
                  onClick={() => {
                    setSearchValue('');
                    setSearchResults(null);
                  }}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
              <Card
                sx={{
                  ml: 'auto',
                  mr: 2,
                  py: 0.5,
                  px: 1,
                  background: theme.colors.alpha.black[10]
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  esc
                </Typography>
              </Card>
            </Box>
          </form>
          <Stack direction="row" spacing={2} p={2}>
            {features.map((feat) => (
              <Chip
                color={feat === inFeature ? 'primary' : 'secondary'}
                variant="outlined"
                label={feat}
                onClick={() => setInFeature(feat)}
                onDelete={feat === inFeature && (() => setInFeature(null))}
              />
            ))}
          </Stack>
        </Box>

        <Divider />

        {!searchLoading && (
          <>
            {!searchResults && (
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  background: theme.colors.info.lighter,
                  color: theme.colors.info.main,
                  borderRadius: theme.general.borderRadius,
                  fontSize: theme.typography.pxToRem(13),
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  mx: 2,
                  my: 2
                }}
              >
                <ContactSupportTwoToneIcon
                  sx={{
                    mr: 0.8,
                    fontSize: theme.typography.pxToRem(18)
                  }}
                />
                {t('Start typing to see the search results...')}
              </Typography>
            )}
          </>
        )}
        {searchLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 5
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {searchResults ? (
              <Box
                sx={{
                  height: '60vh'
                }}
              >
                <Scrollbar>
                  <List>
                    <DeviceResults
                      devices={searchResults.Devices}
                      handleClose={handleClose}
                    />
                    <VehicleResults
                      vehicles={searchResults.Vehicles}
                      handleClose={handleClose}
                    />
                    <UserResults
                      users={searchResults.Users}
                      handleClose={handleClose}
                    />
                  </List>
                </Scrollbar>
              </Box>
            ) : (
              <Box
                sx={{
                  height: 450
                }}
              >
                <Scrollbar>
                  <Box pb={2} px={2}>
                    {/* <Typography
                      sx={{
                        pb: 0.5
                      }}
                      variant="h5"
                    >
                      {t('Recent searches')}
                    </Typography>
                    <ListButton>
                      <Box display="flex" alignItems="center">
                        <RestoreTwoToneIcon
                          sx={{
                            mr: 1
                          }}
                          fontSize="small"
                        />
                        <Typography>Analytics dashboard</Typography>
                      </Box>
                      <Box>
                        <Tooltip
                          placement="top"
                          arrow
                          title={t('Save this search')}
                        >
                          <IconButton size="small" color="primary">
                            <StarTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          placement="top"
                          arrow
                          title={t('Remove this search from history')}
                        >
                          <IconButton size="small" color="error">
                            <CloseTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </ListButton>
                    <ListButton>
                      <Box display="flex" alignItems="center">
                        <RestoreTwoToneIcon
                          sx={{
                            mr: 1
                          }}
                          fontSize="small"
                        />
                        <Typography>Top navigation layout</Typography>
                      </Box>
                      <Box>
                        <Tooltip
                          placement="top"
                          arrow
                          title={t('Save this search')}
                        >
                          <IconButton size="small" color="primary">
                            <StarTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          placement="top"
                          arrow
                          title={t('Remove this search from history')}
                        >
                          <IconButton size="small" color="error">
                            <CloseTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </ListButton> */}
                    {/* <Typography
                      sx={{
                        pt: 2,
                        pb: 0.5
                      }}
                      variant="h5"
                    >
                      {t('Saved searches')}
                    </Typography>
                    <ListButton>
                      <Box display="flex" alignItems="center">
                        <StarTwoToneIcon
                          sx={{
                            mr: 1
                          }}
                          fontSize="small"
                        />
                        <Typography>Hospital overview page</Typography>
                      </Box>
                      <Box>
                        <Tooltip
                          placement="top"
                          arrow
                          title={t('Remove this search from favourites')}
                        >
                          <IconButton size="small" color="error">
                            <CloseTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </ListButton> */}
                    <Divider
                      sx={{
                        my: 4
                      }}
                    />
                    <Typography variant="h5">{t('Quick Links')}</Typography>
                    <Box p={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Box
                            display="flex"
                            mb={1}
                            fontSize={13}
                            alignItems="center"
                          >
                            <DashboardTwoToneIcon
                              sx={{
                                color: theme.colors.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                mr: 1
                              }}
                            />
                            <b>{t('Dashboards')}</b>
                          </Box>
                          <List disablePadding>
                            {[
                              ActivationViewModule,
                              VehicleStatusViewDashboardModule,
                              DashboardViewModule
                            ].map((module) => (
                              <ListItem
                                sx={{
                                  pl: 3,
                                  py: 0.4
                                }}
                                disableGutters
                              >
                                <Link
                                  href={module.path}
                                  color="primary"
                                  fontSize={13}
                                >
                                  {module.name}
                                </Link>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box
                            display="flex"
                            mb={1}
                            fontSize={13}
                            alignItems="center"
                          >
                            <SettingsTwoToneIcon
                              sx={{
                                color: theme.colors.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                mr: 1
                              }}
                            />
                            <b>{t('Management')}</b>
                          </Box>
                          <List disablePadding>
                            {[
                              DeviceViewModule,
                              VehicleViewModule,
                              UserViewModule
                            ].map((module) => (
                              <ListItem
                                sx={{
                                  pl: 3,
                                  py: 0.4
                                }}
                                disableGutters
                              >
                                <Link
                                  href={module.path}
                                  color="primary"
                                  fontSize={13}
                                >
                                  {module.name}
                                </Link>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Scrollbar>
              </Box>
            )}
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
