import React from 'react';
import { eSimProvers } from 'src/enums';
import BrandAutocomplete, { createOption } from '.';

function ESimAutoComplete({ onChange, value, name }) {
  return (
    <BrandAutocomplete
      label="E-SIM Provider"
      placeholder="Select E-SIM Provider..."
      onChange={onChange}
      value={value}
      name={name ?? 'eSimProvider'}
      options={eSimProvers.map((el) => createOption(el, el))}
    />
  );
}

export default ESimAutoComplete;
