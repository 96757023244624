import {
  Box,
  Card,
  Link,
  Typography,
  styled,
  Button,
  Paper
} from '@mui/material';
import { useNavigate } from 'react-router';
import AppConfig from 'src/flavours/index.flavour';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  const navigate = useNavigate();
  return (
    <FooterWrapper className="footer-wrapper" sx={{ mt: '200px' }}>
      <Box
        p={4}
        display={{
          xs: 'block',
          md: 'flex'
        }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; 2023 - {AppConfig.name}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => window.open('public/term-of-use', '_blank')}
            variant="text"
          >
            Terms of use
          </Button>
          <Button
            onClick={() => window.open('public/privacy', '_blank')}
            variant="text"
          >
            Privacy Policy
          </Button>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Crafted by{' '}
          <Link href={AppConfig.site} target="_blank" rel="noopener noreferrer">
            {AppConfig.name}
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
