import { USER_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class UserService extends ApiService {
  constructor() {
    super(USER_URL);
  }

  getUserWithPhone = async (phone, query = {}) => {
    const { data: users } = await this.get({ phone, ...query });
    return users[0];
  };

  getRFC = async (filters = {}) => {
    return this.doGet(
      `${USER_URL}/rfc/get`,
      { ...filters },
      {
        ...this._headers
      }
    );
  };
  createRFCAdmin = async (payload) => {
    return this.doPost(
      `${USER_URL}/rfcAdmin`,
      payload,
      {},
      {
        ...this._headers
      }
    );
  };
}

export default new UserService();
