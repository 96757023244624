import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DeviceViewModule = new Module({
  id: 'device',
  name: 'Device',
  label: 'Device',
  path: 'device',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Devices/View'))
  ),
  actions: {
    edit: {
      id: 'edit-device-result-table',
      name: 'edit',
      label: 'Edit'
    },
    sendCommands: {
      id: 'send-command',
      name: 'send-command',
      label: 'Send Command'
    },
    showVehicle: {
      id: 'show-vehicle-from-device',
      name: 'showVehicle',
      label: 'Show Vehicle'
    },
    rollbackDevice: {
      id: 'rollback-device',
      name: 'rollbackDevice',
      label: 'Rollback Device'
    },
    rollbackBulk: {
      id: 'rollback-device-bulk',
      name: 'rollbackDeviceBulk',
      label: 'Rollback Device'
    },
    allocateDeviceBulk: {
      id: 'allocate-device-bulk',
      name: 'allocateDeviceBulk',
      label: 'Allocate Device'
    },
    updateFirmware: {
      id: 'update-firmware',
      name: 'updateFirmware',
      label: 'Update Firmware'
    },
    likUnlinkDevice: {
      id: 'link-unlink-device',
      name: 'linkUnlinkDevce',
      label: 'Vehicle Link/Unlink'
    },
    raiseSimActivation: {
      id: 'raise-sim-activation',
      name: 'raiseSimActivation',
      label: 'Raise SIM Activation'
    },
    createActivation: {
      id: 'create-activation',
      name: 'createActivation',
      label: 'Create Activation'
    },
    delete: {
      id: 'delete-device-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const DeviceEditModule = new Module({
  id: 'device-edit',
  name: 'DeviceEdit',
  path: 'device/edit',
  pathDef: 'device/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Devices/Form'))
  )
});

export const DeviceAddModule = new Module({
  id: 'device-add',
  name: 'DeviceAdd',
  path: 'device/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Devices/Form'))
  ),
  actions: {
    add: {
      id: 'device-add',
      name: 'Device Add',
      label: 'Add Device'
    }
  }
});

const DeviceModules = [DeviceViewModule, DeviceEditModule, DeviceAddModule];

export default new ModuleBundle('Device', DeviceModules);
