import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const OrganisationViewModule = new Module({
  id: 'organisation-operation',
  name: 'Organisation',
  path: 'organisation',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Organisation/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-organisation-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-organisation-route-playback',
      label: 'See Route Playback'
    }
  }
});

export const OrganisationAddModule = new Module({
  id: 'organisation-add',
  name: 'organisationAdd',
  path: 'organisation/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Organisation/Form/add'))
  ),
  actions: {
    add: {
      id: 'add-organisation',
      name: 'add-organisation',
      label: 'Add organisation'
    }
  }
});

export const OrganisationEditModule = new Module({
  id: 'organisation-edit',
  name: 'OrganisationEdit',
  path: 'organisation/edit',
  pathDef: 'organisation/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Organisation/Form/edit'))
  ),
  actions: {
    edit: {
      id: 'edit-organisation-result-table',
      name: 'edit-organisation-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-organisation-result-table',
      name: 'delete-organisation-result-table',
      label: 'Delete'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    },
    opensetting: {
      id: 'setting-organisation',
      name: 'setting-organisation',
      label: 'Open Settings'
      
    },
  }
});



const OrganisationModules = [
  OrganisationViewModule,
  OrganisationEditModule,
  OrganisationAddModule
];

export default new ModuleBundle('Organisation', OrganisationModules);
