import React, { useEffect, useState } from 'react';
import DeviceService from 'src/services/Device.service';
import cleanObject from 'src/utils/cleanObject';
import BrandAutocomplete, { createOptionsFromArr } from '.';

function IMEIAutoComplete({
  onChange,
  value,
  name,
  label,
  noLabel = false,
  multiple = false,
  filter = {},
  ...rest
}) {
  const [options, setOptions] = useState([]);

  const fetchDevice = async () => {
    const validFilter = cleanObject(filter);
    const { data } = await DeviceService.get(validFilter);
    setOptions(data || []);
  };

  useEffect(() => {
    fetchDevice();
  }, [Object.values(filter).join('')]);
  return (
    <BrandAutocomplete
      label={label ?? 'Device'}
      noLabel={noLabel}
      placeholder="Select device..."
      onChange={onChange}
      value={value}
      multiple={multiple}
      name={name ?? 'device'}
      options={createOptionsFromArr(options, 'imei', 'imei')}
      {...rest}
    />
  );
}

export default IMEIAutoComplete;
