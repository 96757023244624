import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const FSLiveTrackingModule = new Module({
  id: 'fs-tracking',
  name: 'Fullscreen Tracking',
  label: 'Fullscreen Tracking',
  path: 'fs-tracking',
  Component: ComponentLoader(lazy(() => import('src/content/FSLiveTracking')))
});

export default new ModuleBundle('FULLSCREEN TRACKING', [FSLiveTrackingModule]);
