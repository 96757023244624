import { SIM_ACTIVATION_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class SimActivationService extends ApiService {
  constructor() {
    super(SIM_ACTIVATION_URL);
  }
}

export default new SimActivationService();
