import React from 'react';

function CustomImage({ src, alt }) {
  return (
    <img
      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
      src={src}
      alt={alt || '...'}
    />
  );
}

export default CustomImage;
