/* eslint-disable no-unused-vars */
// import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import BrandInput from 'src/components/FormInputs/BrandInput';

import {
  Grid,
  DialogContent,
  Box,
  CircularProgress,
  Button
} from '@mui/material';
import useQueryParams from 'src/hooks/useQueryParams';
import cleanObject from 'src/utils/cleanObject';
import SimActivationOrderStatusAutoComplete from 'src/components/FormInputs/BrandAutocomplete/SimActivationOrderStatusAutoComplete';
import SimActivationApprovalStatusAutocomplete from 'src/components/FormInputs/BrandAutocomplete/SimActivationApprovalStatusAutocomplete';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';

function Filter({ setFilters, filter }) {
  const { t } = useTranslation();

  const isMountedRef = useRefMounted();
  const { user } = useAuth();
  const [params, setParams] = useQueryParams();
  const activationStatues = ['INITIATED', 'APPROVED', 'COMPLETED', 'REJECTED'];

  useEffect(() => {
    const simStatus = activationStatues[0];
    setParams({ simStatus, ...params });
    setFilters({ simStatus, ...params });
  }, [user]);

  return (
    <>
      <Formik
        initialValues={{ ...params }}
        // validationSchema={Yup.object().shape({
        //   number: Yup.string()
        //     .max(255)
        //     .required(t('The device number field is required'))
        // })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const filteredValues = cleanObject({
              ...values
            });

            setParams(filteredValues);
            setFilters?.(filteredValues);

            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3}>
                <EnumAutocomplete
                  ENUM={activationStatues}
                  name="simStatus"
                  label="Sim Activaton Status"
                  onChange={setFieldValue}
                  value={values.simStatus}
                />
                <BrandInput
                  name="imei"
                  label="IMEI"
                  placeholder="Imei here..."
                  onChange={handleChange}
                  value={values.imei}
                />
              </Grid>
            </DialogContent>

            <Box
              sx={{
                display: { xs: 'flex', sm: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <Button
                fullWidth
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
                size="large"
              >
                {t('Apply')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default Filter;
