import DateTimeService from 'src/utils/DateTimeService';

const certificateDataFormat = (certificateData) => {
  const vehicleDetails = [
    {
      header: 'RTO Name',
      accessor: certificateData?.RTO?.name || '-'
    },
    {
      header: 'Registration No.',
      accessor: certificateData?.registrationNumber || '-'
    },
    {
      header: 'Registration Date',
      accessor: certificateData?.registrationYear
        ? DateTimeService.getDateString(certificateData?.registrationYear)
        : '-'
    },
    {
      header: 'Engine No.',
      accessor: certificateData?.engineNo || '-'
    },
    {
      header: 'Category',
      accessor: certificateData?.category || '-'
    },
    {
      header: 'Chassis No.',
      accessor: certificateData?.chassisNo || '-'
    },
    {
      header: 'Make',
      accessor: certificateData?.make || '-'
    },
    {
      header: 'Model',
      accessor: certificateData?.model || '-'
    },
    {
      header: 'Panic Button',
      accessor: certificateData?.device?.panicButtonCount || '-'
    }
  ];

  const deviceDetails = [
    {
      header: 'UID',
      accessor: certificateData?.device?.UID || '-'
    },
    {
      header: 'IMEI',
      accessor: certificateData?.device?.imei || '-'
    },
    {
      header: 'Model',
      accessor: certificateData?.device?.model || '-'
    },
    {
      header: 'Sim 1',
      accessor: certificateData?.device?.sim1 || '-'
    },
    {
      header: 'Sim 2',
      accessor: certificateData?.device?.sim2 || '-'
    },
    {
      header: 'Sim Activation Date',
      accessor: certificateData?.device?.simActivationDate
        ? DateTimeService?.getDateString(
            certificateData?.device?.simActivationDate
          )
        : '-'
    },
    {
      header: 'Installation Date',
      accessor: certificateData?.device?.installationDate
        ? DateTimeService.getDateString(
            certificateData?.device?.installationDate
          )
        : '-'
    },
    {
      header: 'Expires On',
      accessor: certificateData?.device?.expiresOn
        ? DateTimeService?.getDateString(certificateData?.device?.expiresOn)
        : '-'
    },
    {
      header: 'TAC',
      accessor: certificateData?.device?.TAC || '-'
    },
    {
      header: 'ICCID',
      accessor: certificateData?.device?.iccid || '-'
    }
  ];

  const ownerDetails = [
    {
      header: 'Name',
      accessor: certificateData?.owner?.firstName
        ? `${certificateData?.owner?.firstName} ${certificateData?.owner?.lastName}`
        : '-'
    },
    {
      header: 'Email',
      accessor: certificateData?.owner?.email || '-'
    },
    {
      header: 'Phone',
      accessor: certificateData?.owner?.phone || '-'
    },
    {
      header: 'Address',
      accessor: certificateData?.owner?.address || '-'
    }
  ];

  const vendorDetails = [
    {
      header: 'Name',
      accessor: certificateData?.vendor?.brandName ?? ''
    },
    {
      header: 'Email',
      accessor: certificateData?.vendor?.email || ''
    },
    {
      header: 'Phone',
      accessor: certificateData?.vendor?.contact || ''
    },
    {
      header: 'Address',

      accessor: (() => {
        const {
          addressLine1,
          addressLine2,
          pincode,
          area,
          city,
          state,
          country
        } = certificateData?.vendor?.address ?? {};
        return [addressLine1, addressLine2, pincode, area, city, state, country]
          .filter(Boolean)
          .join(', ');
      })()
    }
  ];

  const fitterDetails = [
    {
      header: 'Name',
      accessor: certificateData?.device?.fitment?.by?.firstName
        ? `${certificateData?.device?.fitment?.by?.firstName} ${certificateData?.device?.fitment?.by?.lastName}`
        : '-'
    },
    {
      header: 'Email',
      accessor: certificateData?.device?.fitment?.by?.email || '-'
    },
    {
      header: 'Phone',
      accessor: certificateData?.device?.fitment?.by?.phone || '-'
    },
    {
      header: 'Address',
      accessor: certificateData?.device?.fitment?.by?.address || '-'
    }
  ];

  const certificateLayout = [
    {
      header: 'Vehicle Details',
      data: vehicleDetails
    },
    {
      header: 'Device Details',
      data: deviceDetails
    },
    {
      header: 'Vendor Details',
      data: vendorDetails
    },
    {
      header: 'Owner Details',
      data: ownerDetails
    },
    {
      header: 'Fitter Details',
      data: fitterDetails
    }
  ];

  return certificateLayout;
};

export default certificateDataFormat;
