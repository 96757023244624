import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DeviceDetailsViewModule = new Module({
  id: 'device-details',
  name: 'Device Details',
  label: 'Device Details',
  path: 'device-details/:searchTerm',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/DeviceDetails/View'))
  )
});

const DeviceDetailsModules = [DeviceDetailsViewModule];

export default new ModuleBundle('Device-Details', DeviceDetailsModules);
