import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const VehicleViewModule = new Module({
  id: 'vehicle',
  name: 'Assets',
  path: 'vehicle',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Vehicles/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-vehicle-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-vehicle-route-playback',
      label: 'See Route Playback'
    },
    generateCertificate: {
      id: 'generate-certificate',
      name: 'generate-vehicle-certificate',
      label: 'Generate Certificate'
    },
    editCertificate: {
      id: 'edit-certificate',
      name: 'edit-vehicle-certificate',
      label: 'Edit Certificate'
    },
    viewCertificate: {
      id: 'view-certificate',
      name: 'view-vehicle-certificate',
      label: 'View Certificate'
    },
    assignGeofence: {
      id: 'assign-geofence',
      name: 'assign-vehicle-geofence',
      label: 'Assign Geofence'
    },
    activateTracking: {
      id: 'activated-tracking',
      name: 'activate-vehicle-tracking',
      label: 'Activate Tracking'
    },
    deActivateTracking: {
      id: 'deActivated-tracking',
      name: 'deActivate-vehicle-tracking',
      label: 'Discontinue Tracking'
    }
  }
});

export const VehicleAddModule = new Module({
  id: 'vehicle-add',
  name: 'VehicleAdd',
  path: 'vehicle/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Vehicles/Form'))
  ),
  actions: {
    add: {
      id: 'add-vehicle',
      name: 'add-vehicle',
      label: 'Add vehicle'
    }
  }
});

export const VehicleEditModule = new Module({
  id: 'vehicle-edit',
  name: 'VehicleEdit',
  path: '/vehicle/edit',
  pathDef: 'vehicle/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Vehicles/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-vehicle-result-table',
      name: 'edit-vehicle-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-vehicle-result-table',
      name: 'delete-vehicle-result-table',
      label: 'Delete'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    }
  }
});

const VehicleModules = [VehicleViewModule, VehicleEditModule, VehicleAddModule];

export default new ModuleBundle('Vehicle', VehicleModules);
