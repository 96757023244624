import React, { useEffect, useState } from 'react';
import { Card, CircularProgress, Grid } from '@mui/material';
import PageHeader from 'src/components/PageHeader';
import WalletService from 'src/services/erp/Wallet.service';
import UserService from 'src/services/erp/User.service';
import useAuth from 'src/hooks/useAuth';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import Action from 'src/config/models/Action';
import { ROLES } from 'src/enums';
import Filter from './Filter';
import ResultTable from './ResultTable';
import WalletBalance from './WalletBalance';

function WalletView() {
  const [isGain, setIsGain] = useState(true);
  const [deltaBalance, setDeltaBalance] = useState(0);
  const [filter, setFilter] = useState({});
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [walletId, setWalletId] = useState('');
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const handleTotalAmountChange = (totalCredit, totalDebit) => {
    const delta = totalCredit - totalDebit;
    setDeltaBalance(delta);
    const newIsGain = delta >= 0;
    setIsGain(newIsGain);
  };

  const fetchWalletDetails = async () => {
    try {
      setLoading(true);
      const {
        data: [userdata]
      } = await UserService.get({ phoneNumber: user?.phone });
      const {
        data: [wallet]
      } = await WalletService.get({ userId: userdata?._id });
      setWalletId(wallet?._id);
      setBalance(wallet?.balance);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  return (
    <>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <PageHeader
          heading="Wallet"
          subtitle="See your credit balance and transaction history"
          Icon={AccountBalanceWalletRoundedIcon}
        >
          <Filter filter={filter} setFilter={setFilter} />
        </PageHeader>
        <Grid item xs={12} lg={4}>
          {loading && <CircularProgress />}
          {!loading && (
            <Card>
              <WalletBalance
                balance={balance}
                deltaBalance={deltaBalance}
                isGain={isGain}
              />
            </Card>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          {loading && <CircularProgress />}
          {!loading && (
            <Card>
              <ResultTable
                filters={filter}
                walletId={walletId}
                crdbDiff={handleTotalAmountChange}
              />
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default WalletView;
