import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const SettingViewModule = new Module({
  id: 'setting-operation',
  name: 'Settings',
  path: 'settings/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Setting/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-organisation-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-organisation-route-playback',
      label: 'See Route Playback'
    }
  }
});

const SettingModules = [SettingViewModule];

export default new ModuleBundle('Setting', SettingModules);
