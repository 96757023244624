import { ORGANISATION_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class OrganisationService extends ApiService {
  constructor() {
    super(ORGANISATION_URL);
  }
  getOrganisationSetting=(orgId)=>{
    return this.doGet(`${ORGANISATION_URL}/settings/${orgId}`, {
      ...this._headers
    });
  }
  UpdateOrganisationSetting=(orgId,payload)=>{
    return this.doPut(`${ORGANISATION_URL}/settings/${orgId}`, payload, {
      ...this._headers
    });
  }
}

export default new OrganisationService();
