const id = 'base';
const fullName = 'VLTD AIS-140';
const name = 'VLTD AIS-140';
const logo = 'src/assets/images/base.png';

const loginPageText = `
AIS140 is India's Automotive Industry Standard 140 for ITS implementation in public transport, using GPS and GPRS. It mandates GPS devices, emergency buttons for safety, efficiency, and fleet management.
`;

const site = 'https://traxsmart.in';

const prodUrl = 'https://vltd-api.traxsmart.in/v1';

const locationApiKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVkxURCIsImlhdCI6MTcxNDQ3MzQ5MH0.4SoKstY_LRuz9axnSzCfm7VI3OAcolIUzzT2tiolg9M';

const pallete = {
  primary: '#5569ff',
  secondary: '#6E759F',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57'
};

const config = {
  id,
  fullName,
  name,
  pallete,
  logo,
  loginPageText,
  site,
  prodUrl,
  locationApiKey,
  defaultPass: 'password1$'
};

export default config;
