function getPropByString(obj, propString) {
  // return _.get(obj, propString, "-");
  if (!propString) return obj;

  // eslint-disable-next-line one-var
  let prop,
    props = propString.split('.');

  let i = 0;
  let iLen;

  for (i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    let candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

function getTableData(tableConfig, tableData) {
  const headers = tableConfig?.map((el) => el.Header);
  const accessors = tableConfig?.map(({ accessor }) => accessor);

  const accessRow = (data) => {
    return accessors?.map((el) => {
      if (typeof el === 'string') return getPropByString(data, el);
      if (typeof el === 'function') return el(data);
      return '';
    });
  };
  console.log(headers, tableData);
  const data = tableData?.map((el, idx) => accessRow(el, idx));
  return [headers, data];
}

export default getTableData;
