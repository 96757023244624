import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const jobViewModule = new Module({
  id: 'job',
  name: 'Job',
  label: 'Job',
  path: 'job',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Job/View'))
  ),
  actions: {}
});

export const jobAddModule = new Module({
  id: 'job-add',
  name: 'Job Add',
  path: 'job/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Job/Form'))
  ),
  actions: {
    add: {
      id: 'job-add',
      name: 'job Add',
      label: 'Add job'
    }
  }
});

const jobModules = [jobViewModule, jobAddModule];

export default new ModuleBundle('jobs', jobModules);
