const id = 'PSDN';
const name = 'PSDN';
const fullName = 'PSDN Technology Private Limited';
const logo = 'src/assets/images/PSDN.png';

const loginPageText = `
PSDN Technology Private Limited founded in 2019 is an acclaimed GPS Tracking Device manufacturing company providing alongside hardware and software solutions. We make industry best innovative solutions that cater to suit offshore and onshore custom requirements. Our primary portfolio of products includes vehicle tracking and communication systems with supportive solutions like GPS tracking software, WCDMA/Google positioning, OBD & CAN bus related services, GPS tracker for car, GPS tracker ODM, GPS tracker OEM, and individual, vehicle and pet positioning.`;

const site = 'https://www.psdn.in/';

const thisSite = 'https://vltd.psdn.live/';

const prodUrl = 'https://psdnapi.traxsmart.in/v1';

const locationApiKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiUFNETiIsImlhdCI6MTcxNDQ3MzQxOH0.Boq9CIsuAi71ucOKzmX4B1MrSdCnsFImpUVm96P6veE';

const pallete = {
  primary: '#049333',
  secondary: '#f37c04',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#68696f'
};

const config = {
  id,
  fullName,
  name,
  loginPageText,
  pallete,
  logo,
  site,
  prodUrl,
  locationApiKey,
  thisSite,
  defaultPass: '12345678'
};

export default config;
