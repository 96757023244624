import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const GroupsViewModule = new Module({
  id: 'groups',
  name: 'Groups',
  path: 'groups',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Groups/View/'))
  )
});

export const GroupAddModule = new Module({
  id: 'groups-add',
  name: 'GroupsAdd',
  path: 'groups/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Groups/Form/'))
  ),
  actions: {
    add: {
      id: 'add-Groups',
      name: 'add-Groups',
      label: 'Add Groups'
    }
  }
});

export const GroupsEditModule = new Module({
  id: 'groups-edit',
  name: 'GroupsEdit',
  path: 'groups/edit',
  pathDef: 'groups/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Groups/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-Groups-result-table',
      name: 'edit-Groups-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-Groups-result-table',
      name: 'delete-Groups-result-table',
      label: 'Delete'
    }
  }
});

const GroupsModules = [GroupsViewModule, GroupAddModule, GroupsEditModule];
export default new ModuleBundle('Group Management', GroupsModules);

// export default GroupsModules;
