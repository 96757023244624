import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { TraxsmartThemeDark } from './schemes/TraxsmartThemeDark';
import { TraxsmartTheme } from './schemes/TraxsmartTheme';

const prefersDarkColorScheme = () =>
  window &&
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import stylisRTLPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'swm-ui',
// prepend: true,
//
//   stylisPlugins: [stylisRTLPlugin]
// });

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
  // const curThemeName = localStorage.getItem('appTheme') || 'GreyGooseTheme';
  const theme = prefersDarkColorScheme() ? TraxsmartThemeDark : TraxsmartTheme;

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
