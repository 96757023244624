import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const OfflineReportModule = new Module({
  id: 'offline-report',
  name: 'Offline Report',
  path: 'offline',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/OfflineReport'))
  )
});
