import React, { useEffect, useState } from 'react';
import UserService from 'src/services/User.service';
import RoleService from 'src/services/Role.service';
import useAuth from 'src/hooks/useAuth';
import BrandAutocomplete, { createOptionsFromArr } from '.';

export default function VendorAutocomplete({
  onChange,
  value,
  name,
  selectKey,
  label,
  placeholder,
  multiple = false,
  noLabel
}) {
  const [options, setOptions] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    (async () => {
      const {
        data: [roleData]
      } = await RoleService.get({ name: 'RFC_ADMIN' });

      const { data } = await UserService.get({ role: roleData?.id });

      setOptions(data || []);
    })();
  }, []);
  return (
    <BrandAutocomplete
      label={label ?? 'Vendor'}
      noLabel={noLabel}
      placeholder={placeholder ?? 'Select vendor...'}
      onChange={onChange}
      value={value}
      multiple={multiple}
      name={name ?? 'vendor'}
      options={createOptionsFromArr(options, 'firstName', selectKey ?? 'id')}
    />
  );
}
