import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const POIViewModule = new Module({
  id: 'POI-operation',
  name: 'POI',
  path: 'POI',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/POI/View'))
  )
});

export const POIAddModule = new Module({
  id: 'POI-add',
  name: 'POIAdd',
  path: 'POI/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/POI/Form'))
  ),
  actions: {
    add: {
      id: 'add-POI',
      name: 'add-POI',
      label: 'Add POI'
    }
  }
});

export const POIEditModule = new Module({
  id: 'POI-edit',
  name: 'POIEdit',
  path: 'POI/edit',
  pathDef: 'POI/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/POI/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-POI-result-table',
      name: 'edit-POI-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-POI-result-table',
      name: 'delete-POI-result-table',
      label: 'Delete'
    }
  }
});

const POIModules = [POIViewModule, POIEditModule, POIAddModule];

export default new ModuleBundle('POI', POIModules);
