import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const UserViewModule = new Module({
  id: 'user-operation',
  name: 'User',
  path: 'user',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Users/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-user-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-user-route-playback',
      label: 'See Route Playback'
    }
  }
});

export const UserAddModule = new Module({
  id: 'user-add',
  name: 'UserAdd',
  path: 'user/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Users/Form'))
  ),
  actions: {
    add: {
      id: 'add-user',
      name: 'add-user',
      label: 'Add user'
    }
  }
});

export const UserEditModule = new Module({
  id: 'user-edit',
  name: 'UserEdit',
  path: 'user/edit',
  pathDef: 'user/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Users/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-user-result-table',
      name: 'edit-user-result-table',
      label: 'Edit'
    },
    setNewPassword: {
      id: 'set-user-password',
      name: 'set-user-password',
      label: 'Set New Password'
    },
    delete: {
      id: 'delete-user-result-table',
      name: 'delete-user-result-table',
      label: 'Delete'
    },
    associate: {
      id: 'associate-user-result-table',
      name: 'associate-user-result-table',
      label: 'Association'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    }
  }
});

const UserModules = [UserViewModule, UserEditModule, UserAddModule];

export default new ModuleBundle('User', UserModules);
