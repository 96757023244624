import { RTO_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class RTOService extends ApiService {
  constructor() {
    super(RTO_URL);
  }
}

export default new RTOService();
