import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

function CertificateSectionLayout({ data }) {
  return (
    <Table sx={{ border: 'none' }}>
      <TableBody>
        {data
          ?.filter((item) => item?.accessor !== (null || undefined || ''))
          .reduce((result, item, index) => {
            if (index % 4 === 0) result.push([]);
            result[result.length - 1].push(item);
            return result;
          }, [])
          .map((chunk) => (
            <TableRow>
              {chunk.map((item) => (
                <TableCell sx={{ borderBottom: 'none' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {item?.header}
                  </Typography>
                  <Typography>{item?.accessor}</Typography>
                </TableCell>
              ))}
              {Array(4 - chunk.length)
                .fill(null)
                .map(() => (
                  <TableCell sx={{ borderBottom: 'none' }} />
                ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default CertificateSectionLayout;
