import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';
import BrandInput from 'src/components/FormInputs/BrandInput';
import BrandAutocomplete from 'src/components/FormInputs/BrandAutocomplete';

import {
  Grid,
  DialogContent,
  Box,
  TextField,
  CircularProgress,
  Button
  //   useTheme
  //   useMediaQuery
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/lab';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import BooleanAutocomplete from 'src/components/FormInputs/BrandAutocomplete/BooleanAutoComplete';
import { useState, useEffect, useRef } from 'react';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import RoleAutocomplete from 'src/components/FormInputs/BrandAutocomplete/RoleAutoComplete';
import cleanObject from 'src/utils/cleanObject';
import toast from 'react-hot-toast';
import BrandDatePicker from 'src/components/FormInputs/BrandDatePicker';
import DateTimeService from 'src/utils/DateTimeService';

function Filter({ filter, setFilter }) {
  const { t } = useTranslation();

  //   const theme = useTheme();

  //   const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMountedRef = useRefMounted();
  // useEffect(() => {
  //   if (isMountedRef.current) {
  //     setFilter({
  //       isEnabled: true
  //     });
  //   }
  // }, [isMountedRef, setFilter]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={filter}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            values = cleanObject(values);
            if (values?.toDate)
              values.toDate = DateTimeService.getMidnightTime(values.toDate);
            if (values?.fromDate)
              values.fromDate = DateTimeService.getMorningTime(values.fromDate);
            setFilter(cleanObject(values));
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            // style={{
            //   display: 'flex',
            //   flexDirection: { xs: 'row', sm: 'row', md: 'row' },
            //   alignItems: 'flex-end',
            //   gap: 10
            // }}
          >
            <Box
              display="flex"
              alignItems={{ xs: 'stretch', sm: 'flex-end', md: 'flex-end' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
              gap={1.5}
            >
              <EnumAutocomplete
                ENUM={['CREDIT', 'DEBIT']}
                value={values.transactionType}
                name="transactionType"
                label="Transaction Type"
                placeholder="Select Transaction Type"
                onChange={setFieldValue}
              />
              <BrandDatePicker
                name="fromDate"
                value={values.fromDate}
                label="From Date"
                onChange={setFieldValue}
              />

              <BrandDatePicker
                name="toDate"
                value={values.toDate}
                label="To Date"
                onChange={setFieldValue}
              />
              <Box
                sx={{
                  display: { xs: 'flex', sm: 'flex' },
                  alignItems: 'flex',
                  justifyContent: 'space-between',
                  margin: 0.5
                }}
              >
                <Button
                  fullWidth
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="outlined"
                  size="large"
                >
                  {t('Apply')}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default Filter;
