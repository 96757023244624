import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import SheetImg from '../../assets/images/ss_sheet.png';
import CustomImage from './CustomImage';

function CSVuploadManual({ setModalOpen, modalOpen, title, gId }) {
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title || 'CSV Upload Manual'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CustomImage src={SheetImg} />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            window.open(
              `https://docs.google.com/spreadsheets/d/14udjae_d3L4nZCBg4ctKxnO6eZlw3O4LX9ZCGnsxRYw/export?format=csv&gid=${gId}`
            );
          }}
        >
          Download Sample CSV
        </Button>
        <Button
          color="info"
          onClick={() => {
            window.open(
              `https://docs.google.com/spreadsheets/d/14udjae_d3L4nZCBg4ctKxnO6eZlw3O4LX9ZCGnsxRYw/edit?usp=sharing#gid=${gId}`
            );
          }}
        >
          Clone Sample CSV
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CSVuploadManual;
