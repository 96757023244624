/* eslint-disable jsx-a11y/accessible-emoji */
import { Dialog, DialogContent, DialogTitle, List } from '@mui/material';
import React from 'react';

function Message() {
  return (
    <Dialog onClose={() => {}} open>
      <DialogTitle>
        <h1>
          Currently Unavailable <span role="img">😥</span>
        </h1>
      </DialogTitle>
      <DialogContent>
        Apologies for any inconvenience caused, but our data center is currently
        experiencing power issues. Please try again later, and we appreciate
        your understanding.
      </DialogContent>
    </Dialog>
  );
}

export default Message;
