import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import registerModuleWithMenu from 'src/config/AccessControl/registerModuleWithMenu';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded';

import { DeviceViewModule } from 'src/content/management/Devices/module';
import { VehicleViewModule } from 'src/content/management/Vehicles/module';
import { UserViewModule } from 'src/content/management/Users/module';
import { GeofenceViewModule } from 'src/content/management/GeoFences/module';
import { OrganisationViewModule } from 'src/content/management/Organisation/module';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import CarRepairRoundedIcon from '@mui/icons-material/CarRepairRounded';
import { AlertReportModule } from 'src/content/reports/Alert/module';
import { RawLogsViewModule } from 'src/content/reports/RawLogs/module';
import { VehicleTimelineModule } from 'src/content/reports/VehicleTimeline/module';
import { MonthlySummaryModule } from 'src/content/reports/MonthlySummary/module';
import { DailyRunSummaryModule } from 'src/content/reports/DailyRunSummary/module';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { RoleViewModule } from 'src/content/management/Role/module';
import { VehicleStatusViewDashboardModule } from 'src/content/dashboards/VehicleStatus/module';
import { SosAlertModule } from 'src/content/reports/SosAlert/module';
import { DutyReportModule } from 'src/content/reports/DutyReport/module';
import GroupsModules, {
  GroupsViewModule
} from 'src/content/management/Groups/module';
import { POIViewModule } from 'src/content/management/POI/module';
import { jobViewModule } from 'src/content/management/Job/module';
import { routeViewModule } from 'src/content/management/Routes/module';
import { AdminDivisionViewModule } from 'src/content/management/AdminDivision/module';
import {
  DashboardModule,
  DashboardNewModule
} from 'src/content/dashboards/module';
import { CollectionModule } from 'src/content/reports/CollectionReport/module';
import { SimActivationViewModule } from 'src/content/management/simActivationOrder/modules';
import { DeviceDetailsViewModule } from 'src/content/management/DeviceDetails/module';
import { OfflineReportModule } from 'src/content/reports/OfflineReport/module';
import { AdminDashboardModule } from 'src/content/dashboards/AIS140/module';
import { FSLiveTrackingModule } from 'src/content/FSLiveTracking/module';
import { RouteViolationModule } from 'src/content/reports/RouteViolation/module';
import { D2DWasteCollectionModule } from 'src/content/reports/D2DWasteCollection/module';

const sortAlphaAsc = (a, b) => a.name.localeCompare(b.name);

const menuItems = [
  {
    heading: '',
    id: 'root',
    path: '',
    items: [
      // {
      //   id: 'root',
      //   name: 'Device Status',
      //   icon: DirectionsWalkIcon,
      //   link: '/vehicle-status'
      // },
      {
        id: 'root',
        name: 'Vehicle Status',
        icon: TaxiAlertIcon,
        link: '/vehicle-status'
      },

      registerModuleWithMenu(AdminDashboardModule, {
        icon: DashboardTwoToneIcon
      }),
      registerModuleWithMenu(DashboardNewModule, {
        icon: DashboardTwoToneIcon
      }),
      registerModuleWithMenu(FSLiveTrackingModule, {
        icon: TrackChangesRoundedIcon
      }),
      // // {
      // //   id: 'fitment-records',
      // //   name: 'Fitment Records',
      // //   icon: CarRepairRoundedIcon,
      // //   link: '/fitmentRecords'
      // // },
      {
        id: 'fitment',
        name: 'Fitment',
        icon: ToggleOnOutlinedIcon,
        link: '/fitment'
      },
      registerModuleWithMenu(SimActivationViewModule, {
        icon: SimCardOutlinedIcon
      }),
      // {
      //   id: 'root',
      //   name: 'View Operations',
      //   icon: DirectionsCarFilledTwoToneIcon,
      //   link: '/view-operations',
      //   items: [
      //     {
      //       id: 'root',
      //       name: 'Live Tracking',
      //       link: 'view-operations/live-tracking'
      //     },
      //     {
      //       id: 'root',
      //       name: 'Route Playback',
      //       link: 'view-operations/route-playback'
      //     }
      //   ]
      // },
      // {
      //   name: 'SWM Activity',
      //   icon: LocalShippingTwoToneIcon,
      //   link: '/view-operations',
      //   items: [
      //     {
      //       name: 'Live Tracking',
      //       link: 'view-operations/live-tracking'
      //     },
      //     {
      //       name: 'Route Playback',
      //       link: 'view-operations/route-playback'
      //     }
      //   ]
      // },
      {
        id: 'root',
        name: 'Reports',
        icon: AnalyticsTwoToneIcon,
        path: '/reports',
        items: [
          registerModuleWithMenu(AlertReportModule),
          registerModuleWithMenu(OfflineReportModule),
          // registerModuleWithMenu(SosAlertModule),
          registerModuleWithMenu(VehicleTimelineModule),
          registerModuleWithMenu(MonthlySummaryModule),
          // registerModuleWithMenu(DutyReportModule),
          registerModuleWithMenu(RawLogsViewModule),
          registerModuleWithMenu(DailyRunSummaryModule),
          registerModuleWithMenu(CollectionModule),
          registerModuleWithMenu(RouteViolationModule),
          registerModuleWithMenu(D2DWasteCollectionModule)

          // {
          //   id: 'reports:alert',
          //   name: 'Alert',
          //   link: 'reports/alert'
          // },
          // {
          //   id: 'reports:timeline',
          //   name: 'Vehicle Timeline',
          //   link: 'reports/vehicle-timeline'
          // },
          // {
          //   id: 'reports:summary',
          //   name: 'Summary',
          //   link: 'reports/monthly-summary'
          // }
          // {
          //   id: 'reports:geofence-entry-exits',
          //   name: 'Geofence Entry Exits',
          //   link: 'reports/geofence-entry-exits'
          // },
          // {
          //   id: 'reports:driver-performance',
          //   name: 'Driver Performance',
          //   link: 'reports/driver-performance'
          // },
          // {
          //   id: 'reports:device-tampering',
          //   name: 'Device Tampering',
          //   link: 'reports/device-tampering'
          // },
          // {
          //   id: 'reports:vehicle-allocation',
          //   name: 'Vehicle Allocation',
          //   link: 'reports/vehicle-allocation'
          // },
          // {
          //   id: 'reports:jobs',
          //   name: 'Jobs',
          //   link: 'reports/jobs'
          // },
          // {
          //   id: 'reports:long-stops',
          //   name: 'Long Stops',
          //   link: 'reports/long-stops'
          // },
          // {
          //   id: 'reports:fuel-consumption',
          //   name: 'Fuel Consumption',
          //   link: 'reports/fuel-consumption'
          // },
          // {
          //   id: 'reports:vehicle-login-logoff'',
          //   name: 'Vehicle login/logoff',
          //   link: 'reports/vehilce-login-logoff'
          // },
          //           {
          //             id: 'reports:driver-change',
          //             name: 'Driver Change',
          //             link: 'reports/driver-change'
          //           },
          //           {
          //   id: 'reports:inspection',
          //   name: 'Inspection',
          //   link: 'reports/inspection'
          // },
          // {
          //   id: 'reports:attendance',
          //   name: 'Attendance',
          //   link: 'reports/attendance'
          // },
          // {
          //   id: 'reports:vehicle-duty',
          //   name: 'Vehicle Duty',
          //   link: 'reports/vehicle-duty'
          // },
          // {
          //   id: 'reports:fuel',
          //   name: 'Fuel',
          //   link: 'reports/fuel'
          // },
          // {
          //   id: 'reports:offline-aging',
          //   name: 'Offline Ageing',
          //   link: 'reports/offline-aging'
          // },
          // {
          //   id: 'reports:vehicle-usage',
          //   name: 'Vehicle Usage',
          //   link: 'reports/vehicle-usage'
          // },
          // {
          //   id: 'reports:d2d-waste-collection',
          //   name: 'D2D Waste Collection',
          //   link: 'reports/d2d-waste-collection'
          // },
          // {
          //   id: 'reports:compactor-dumps',
          //   name: 'Compactor Dumps',
          //   link: 'reports/compactor-dumps'
          // },
          // {
          //   id: 'reports:disposal-dumps',
          //   name: 'Disposal Site Dumps',
          //   link: 'reports/disposal-dumps'
          // },
          // {
          //   id: 'reports:route-violation',
          //   name: 'Vehicle Route Violations',
          //   link: 'reports/route-violation'
          // },
          // {
          //   id: 'reports:operation-status',
          //   name: 'Vehicle Operation Status',
          //   link: 'reports/operation-status'
          // },
          // {
          //   id: 'reports/dump-mismatch',
          //   name:'Dump Yard Mismatch',
          //    link:' reports / dump - mismatch'
          // },
          // {
          //    id:' reports:dumpyard-vehicle-presence',
          //    name:'Vehicle Dumpyard Presence',
          //    link:' reports / dumpyard - vehicle - presence'
          // },
          // {
          //    id:'reports:vehicle-disconnected',
          //    name:'Vehicle Disconnected',
          //    link:'reports/vehicle-disconnected'
          // },
          // {
          //    id:' reports:citizen-complaints',
          //    name:'Citizen Complaints',
          //    link:' reports / citizen - complaints'
          // },
          // {
          //    id:' reports:stop-report',
          //    name:'Stop Report',
          //    link:' reports/stop-report'
          // }
        ].sort(sortAlphaAsc)
      },

      {
        name: 'Management',
        icon: SettingsTwoToneIcon,
        id: 'root',
        path: '/management',
        items: [
          // registerModuleWithMenu(GroupsViewModule),
          registerModuleWithMenu(jobViewModule),
          registerModuleWithMenu(DeviceViewModule),
          // registerModuleWithMenu(DeviceDetailsViewModule),
          registerModuleWithMenu(GeofenceViewModule),
          registerModuleWithMenu(VehicleViewModule),
          // registerModuleWithMenu(VehicleStatusViewDashboardModule),
          registerModuleWithMenu(UserViewModule),
          registerModuleWithMenu(RoleViewModule),
          registerModuleWithMenu(OrganisationViewModule),
          registerModuleWithMenu(POIViewModule),
          registerModuleWithMenu(routeViewModule),
          // registerModuleWithMenu(AdminDivisionViewModule)
          // registerModuleWithMenu(POIViewModule),
          // registerModuleWithMenu(routeViewModule),
          registerModuleWithMenu(AdminDivisionViewModule)
        ].sort(sortAlphaAsc)
      }
    ]
  }
];

const filterSidebar = (items, filter) => {
  return (
    items
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item) => {
        if (item.items) item.items = filterSidebar(item.items, filter);
        if (filter(item.id)) return item;
      })
      .filter(Boolean)
  );
};

export { filterSidebar, menuItems };

// export default menuItems;
