import React, { useEffect, useState } from 'react';
import OrganisationService from 'src/services/Organisation.service';
import useAuth from 'src/hooks/useAuth';
import cleanObject from 'src/utils/cleanObject';
import BrandAutocomplete, { createOptionsFromArr } from '.';

function OrganizationAutocomplete({
  onChange,
  value,
  name,
  label,
  placeholder,
  filter = {},
  multiple = false,
  required = false,
  noLabel
}) {
  const { user } = useAuth();
  const [options, setOptions] = useState([]);

  const fetchOrganisation = async () => {
    const validFilter = cleanObject(filter);
    const { data } = await OrganisationService.get({ ...validFilter });

    setOptions(data || []);
  };

  useEffect(() => {
    fetchOrganisation();
  }, [Object.values(filter).join('')]);

  return (
    <BrandAutocomplete
      label={label ?? 'Organisation'}
      noLabel={noLabel}
      onChange={onChange}
      value={value}
      placeholder={placeholder ?? 'Select Organisation'}
      required={required}
      multiple={multiple}
      name={name ?? 'organisation'}
      options={createOptionsFromArr(options, 'tradeName', 'id')}
    />
  );
}

export default OrganizationAutocomplete;
