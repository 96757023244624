import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const ItemViewModule = new Module({
  id: 'item',
  name: 'Item',
  label: 'Item',
  path: 'item',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Item/View'))
  ),
  actions: {
    seeVehicle: {
      id: 'see-attached-vehicle',
      name: 'see-vehicle',
      label: 'See Vehicle'
    },
    edit: {
      id: 'edit-item-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-item-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const ItemAddModule = new Module({
  id: 'item-add',
  name: 'itemAdd',
  path: 'item/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Item/Form'))
  ),
  actions: {
    add: {
      id: 'item-add',
      name: 'item Add',
      label: 'Add Item'
    }
  }
});

const ItemModules = [ItemViewModule, ItemAddModule];

export default new ModuleBundle('Items', ItemModules);
