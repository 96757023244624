import { ORGANISATION_ASSOCIATION } from 'src/config/api.config';
import ApiService from './Api.service';

class OrganisationAssociationService extends ApiService {
  constructor() {
    super(ORGANISATION_ASSOCIATION);
  }
}

export default new OrganisationAssociationService();
