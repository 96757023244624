import base from './base.flavor.js';
import TraxsmartVLTD from './TraxsmartVLTD.flavor.js';
import traxcity from './traxcity.flavor.js';
import PSDN from './PSDN.flavor.js';

const flavor = process.env.REACT_APP_FLAVOUR;

// FIXME: PUT thisSite IN ALL

export const flavors = {
  TraxsmartVLTD,
  traxcity,
  PSDN
};

const AppConfig = flavors[flavor] ?? base;

export default AppConfig;
