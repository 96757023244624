import React from 'react';

import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import SettingsRemoteOutlinedIcon from '@mui/icons-material/SettingsRemoteOutlined';
import { useNavigate } from 'react-router';

function DeviceResults({ devices, handleClose }) {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Box px={2} py={1} key="Devices">
      <Typography
        sx={{
          py: 1
        }}
        variant="h5"
      >
        Devices
      </Typography>
      {!devices.length && (
        <Typography
          fontSize={18}
          fontWeight={300}
          color="grey"
          variant="body2"
          alignItems="center"
          display="flex"
          p={2}
        >
          <SettingsRemoteOutlinedIcon
            sx={{
              mr: 3
            }}
            color="disabled"
          />
          No devices for the query
        </Typography>
      )}
      {devices.map((device) => (
        <>
          <ListItem sx={{ py: 2 }}>
            <ListItemIcon>
              <SettingsRemoteOutlinedIcon
                sx={{
                  mr: 1
                }}
                color={device.active ? 'success' : 'error'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    }}
                    onClick={() => {
                      navigate(`management/device-details/${device.id}`);
                      handleClose();
                    }}
                  >
                    {device.name}
                  </Typography>
                  <Typography variant="h5">{device.imei}</Typography>
                </Stack>
              }
              secondary={
                <Stack direction="row" sx={{ pt: 1 }}>
                  {`${device.iccid} (${device.eSimProvider})`}
                  <Box px={2}>•</Box>
                  {device.organisation?.brandName}
                </Stack>
              }
            />
          </ListItem>
          <Divider />
        </>
      ))}
    </Box>
  );
}

export default DeviceResults;
