import {
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
  Zoom
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ItemIcon from '@mui/icons-material/ExtensionRounded';
import PageHeader from 'src/components/PageHeader';
import { DeleteRounded } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from 'src/services/User.service';
import BrandInput from 'src/components/FormInputs/BrandInput';
import SimCardIcon from '@mui/icons-material/SimCard';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import OrganizationAutocomplete from 'src/components/FormInputs/BrandAutocomplete/OrganizationAutoComplete';
import RoleAutocomplete from 'src/components/FormInputs/BrandAutocomplete/RoleAutoComplete';
import AdminAccess from 'src/config/AccessControl/components/AdminAccess';
import useAuth from 'src/hooks/useAuth';
import RoleService from 'src/services/Role.service';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import { duration } from 'src/enums';
import IMEIAutoComplete from 'src/components/FormInputs/BrandAutocomplete/IMEIAutoComplete';
import toast from 'react-hot-toast';
import SimActivationService from 'src/services/SimActivation.service';

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
       background: ${theme.colors.alpha.black[5]};
       box-shadow: none;
       margin: 0 ${theme.spacing(3)};
  `
);

const tableConfig = [
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Phone no',
    accessor: 'phone'
  },
  {
    Header: 'Email',
    accessor: 'email'
  }
];

function SimActivationOrderForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [simActivation, setSimActivation] = useState({});

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      if (!id) return;

      const { data } = await SimActivationService.get({ _id: id });
      console.log(
        Object.keys(duration).find(
          (key) => duration[key] === simActivation?.durationInDays
        )
      );
      setSimActivation(data?.[0] ?? {});
    })();
  }, [id]);

  const handleBulkSubmit = (e) => {
    e.preventDefault();
  };

  function removeBlankAndNull(arr) {
    return arr.filter((entry) => entry !== '' && entry !== null);
  }

  return (
    <Grid
      sx={{
        px: 4
      }}
      container
      direction="row"
      justifyContent="center"
      spacing={4}
    >
      <PageHeader
        heading="Sim Activation"
        subtitle="Create Sim Activation"
        Icon={SimCardIcon}
      />

      <Grid item xs={12} lg={9}>
        <Card>
          <Formik
            enableReinitialize
            initialValues={{
              ips: [''],
              numbers: [''],
              ...simActivation,
              ...(simActivation?.durationInDays && {
                durationInDays: Object.keys(duration).find(
                  (key) => duration[key] === simActivation?.durationInDays
                )
              }),
              ...(simActivation?.numbers?.length > 0 && {
                numbers: simActivation?.numbers?.map((number) =>
                  number?.replace('+91', '')
                )
              })
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setSubmitting(true);

                const payload = { ...values };

                if (payload?.ips) {
                  payload.ips = removeBlankAndNull(payload.ips);
                }

                if (payload?.numbers) {
                  payload.numbers = removeBlankAndNull(payload.numbers).map(
                    (phone) => `+91${phone}`
                  );
                }
                if (payload?.durationInDays) {
                  payload.durationInDays = duration[payload?.durationInDays];
                }

                const { success } = await (id
                  ? SimActivationService.put(simActivation?.id, payload)
                  : SimActivationService.post(payload));

                setStatus({ success: true });

                enqueueSnackbar(
                  id ? 'Sim Activation updated' : 'Sim Activation created',
                  {
                    variant: success ? 'success' : 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    TransitionComponent: Zoom
                  }
                );

                navigate(-1);

                resetForm();
              } catch (err) {
                console.error(err);
                enqueueSnackbar(err.message, {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                  },
                  TransitionComponent: Zoom
                });
                setStatus({ success: false });
                setErrors({ submit: err.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box p={3}>
                  <Typography variant="h4">Sim Activation Form</Typography>
                </Box>
                <Divider />
                <Box px={3} py={2}>
                  <Grid container spacing={3}>
                    <IMEIAutoComplete
                      label="IMEI"
                      value={values.imei}
                      onChange={setFieldValue}
                      name="imei"
                    />
                    <Grid item xs={12} md={12} m={0}>
                      <Typography m={0} variant="h4">
                        IPs:
                      </Typography>
                    </Grid>
                    <FieldArray
                      name="ips"
                      render={(arrayHelpers) => (
                        <>
                          {values?.ips?.map((friend, index) => (
                            <Grid item xs={12} key={index}>
                              <Stack direction="row" spacing={2}>
                                <BrandInput
                                  name={`ips.${index}`}
                                  placeholder="Enter IP"
                                  value={values.ips?.[index]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  md={6}
                                />
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    values.ips.length > 1
                                      ? arrayHelpers.remove(index)
                                      : alert('Atleast one ips is required')
                                  }
                                >
                                  <DeleteRounded />
                                </IconButton>
                              </Stack>
                            </Grid>
                          ))}
                          {values.ips.length !== 4 && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                aligneItems="center"
                                color="primary"
                                startIcon={<AddOutlinedIcon />}
                                onClick={() => arrayHelpers.push('')}
                              >
                                Add IP
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    />
                    <Grid item xs={12} md={12} m={0}>
                      <Typography m={0} variant="h4">
                        Phone Numbers:
                      </Typography>
                    </Grid>
                    <FieldArray
                      name="numbers"
                      render={(arrayHelpers) => (
                        <>
                          {values?.numbers?.map((friend, index) => (
                            <Grid item xs={12} key={index}>
                              <Stack direction="row" spacing={2}>
                                <BrandInput
                                  name={`numbers.${index}`}
                                  placeholder="Enter Phone Number"
                                  value={values.numbers?.[index]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  md={6}
                                  InputProps={{
                                    inputProps: { maxLength: 10 },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +91
                                      </InputAdornment>
                                    )
                                  }}
                                />
                                <IconButton
                                  color="error"
                                  onClick={() =>
                                    values.numbers.length > 1
                                      ? arrayHelpers.remove(index)
                                      : alert(
                                          'Atleast one Phone Number is required'
                                        )
                                  }
                                >
                                  <DeleteRounded />
                                </IconButton>
                              </Stack>
                            </Grid>
                          ))}
                          {values.numbers.length !== 4 && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                aligneItems="center"
                                color="primary"
                                startIcon={<AddOutlinedIcon />}
                                onClick={() => {
                                  arrayHelpers.push('');
                                }}
                              >
                                Add Phone Number
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    />
                    <EnumAutocomplete
                      ENUM={Object.keys(duration)}
                      value={values.durationInDays}
                      name="durationInDays"
                      label="Activation Duration"
                      onChange={setFieldValue}
                    />
                  </Grid>
                </Box>

                <CardActionsWrapper
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    p: 2
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={isSubmitting}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Box>
                </CardActionsWrapper>
              </form>
            )}
          </Formik>
        </Card>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
}

export default SimActivationOrderForm;
