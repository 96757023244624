import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

function UserResults({ users }) {
  const theme = useTheme();

  return (
    <Box px={2} py={1} key="Users">
      <Typography
        sx={{
          py: 1
        }}
        variant="h5"
      >
        Users
      </Typography>
      {!users.length && (
        <>
          <Typography
            fontSize={18}
            fontWeight={300}
            color="grey"
            variant="body2"
            alignItems="center"
            display="flex"
            p={2}
          >
            <PersonOutlinedIcon
              sx={{
                mr: 3
              }}
              color="disabled"
            />
            No Users for the query
          </Typography>
        </>
      )}
      {users.map((user) => (
        <>
          <ListItem sx={{ py: 2 }}>
            <ListItemIcon>
              <PersonOutlinedIcon
                sx={{
                  mr: 1
                }}
                fontSize="small"
                color={user.isEnabled ? 'success' : 'error'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    }}
                  >
                    {`${user.firstName} ${user.lastName}`}{' '}
                  </Typography>
                  <ButtonGroup>
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<LocalPhoneOutlinedIcon />}
                      href={`tel:${user.phone}`}
                    >
                      {user.phone}
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      href={`mailto:${user.email}`}
                    >
                      <EmailOutlinedIcon />
                    </Button>
                  </ButtonGroup>
                </Stack>
              }
              secondary={
                <Stack direction="row" sx={{ pt: 1 }}>
                  {user.role?.name?.replace(/_/, ' ')}
                  <Box px={2}>•</Box>
                  {user.organisation?.brandName}
                </Stack>
              }
            />
          </ListItem>
          <Divider />
        </>
      ))}
    </Box>
  );
}

export default UserResults;
