import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const GeofenceViewModule = new Module({
  id: 'geofence',
  name: 'Geofence',
  label: 'Geofence',
  path: 'geofence',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/GeoFences/View'))
  ),
  actions: {
    add: {
      id: 'add-geofence',
      name: 'add',
      label: 'Add'
    },
    edit: {
      id: 'edit-geofence',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-geofence',
      name: 'delete',
      label: 'Delete'
    }
  }
});

const GeofenceModules = [GeofenceViewModule];

export default new ModuleBundle('Geofence', GeofenceModules);
