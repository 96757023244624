import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';

function SubSection({ heading, children }) {
  return (
    <>
      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h4" sx={{ py: 1 }}>
          {heading}
        </Typography>
      </Grid>
      {children}
    </>
  );
}

export default SubSection;
