import { ACTIVATION_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class ActivationService extends ApiService {
  constructor() {
    super(ACTIVATION_URL);
  }

  approveActivation = async (activationId) => {
    return this.doPut(`${ACTIVATION_URL}/approve/${activationId}`, {
      ...this._headers
    });
  };

  undoActivation = async (activationId) => {
    return this.doPut(`${ACTIVATION_URL}/undo/${activationId}`, {
      ...this._headers
    });
  };

  bulkActivation = async (payload) => {
    return this.doPost(`${ACTIVATION_URL}/bulk`, payload, {
      ...this._headers
    });
  };
}

export default new ActivationService();
