import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const D2DWasteCollectionModule = new Module({
  id: 'd2d-collection-report',
  name: 'D2D Collection Report',
  path: 'd2d-collection',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/D2DWasteCollection'))
  )
});
