import React from 'react';
import { SimActivationStatusTypes } from 'src/enums';
import BrandAutocomplete, { createOption } from '.';

function SimActivationOrderStatusAutoComplete({ onChange, value, name }) {
  return (
    <BrandAutocomplete
      label="Sim Activation Status"
      placeholder="Select sim activation status..."
      onChange={onChange}
      value={value}
      name={name ?? 'simActivationStatus'}
      options={SimActivationStatusTypes.map((el) => createOption(el, el))}
    />
  );
}

export default SimActivationOrderStatusAutoComplete;
