import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DashboardViewModule = new Module({
  id: 'dashboard',
  name: 'Dashboard',
  path: 'vehicle-status',
  Component: ComponentLoader(
    lazy(() => import('src/content/dashboards/sdDashboard'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-vehicle-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-vehicle-route-playback',
      label: 'See Route Playback'
    },
    assignGeofence: {
      id: 'assign-geofence',
      name: 'assign-vehicle-geofence',
      label: 'Assign Geofence'
    },
    viewReport: {
      id: 'view-report',
      name: 'view-dashboard-report',
      label: 'View Report',
      action: (history, targetedGroup) => {
        history.push(`/duty-report?group=${targetedGroup}`);
      }
    }
  }
});

export const VehicleEditModule = new Module({
  id: 'vehicle-edit',
  name: 'VehicleEdit',
  path: '/vehicle/edit',
  pathDef: 'vehicle/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Vehicles/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-vehicle-result-table',
      name: 'edit-vehicle-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-vehicle-result-table',
      name: 'delete-vehicle-result-table',
      label: 'Delete'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    }
  }
});

const DashboardModules = [DashboardViewModule];

export default new ModuleBundle('Vehicle', DashboardModules);
