import React from 'react';
import useAuth from 'src/hooks/useAuth';

function AdminAccess({ children }) {
  const { user } = useAuth();

  if (user.role !== 'SUPER_ADMIN') return <></>;
  return <>{children}</>;
}

export default AdminAccess;
