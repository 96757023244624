import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const ForgotPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);
const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ResetPassword'))
);

const authRoutes = [
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />
  },
  {
    path: 'reset-password/:otpToken',
    element: <ResetPassword />
  }
];

export default authRoutes;
