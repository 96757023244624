import { ERP_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class ErpService extends ApiService {
  constructor() {
    super(ERP_URL);
  }

  getFitmentCounts = async () => {
    return this.doGet(
      `${ERP_URL}/fitment-counts`,
      {},
      {
        ...this._headers
      }
    );
  };

  getStockCounts = async () => {
    return this.doGet(
      `${ERP_URL}/stock-counts`,
      {},
      {
        ...this._headers
      }
    );
  };

  getSearchResults = async (searchTerm, inFeature) => {
    return this.doGet(
      `${ERP_URL}/search/${searchTerm}`,
      { inFeature },
      {
        ...this._headers
      }
    );
  };
}

export default new ErpService();
