import Authenticated from 'src/components/Authenticated';

import BaseLayout from 'src/layouts/BaseLayout';

// import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import SideBarNavigation from 'src/layouts/AccentSidebarLayout';
import CertificateLayout from 'src/components/Certificate/CertificateLayout';
import WalletView from 'src/content/management/Wallet/View';

import dashboardsRoutes from './dashboards';
import reportsRoutes from './reports';
import statusRoutes from './base';
import authRoutes from './auth';
import { privateCertificateRoutes } from './certificate.route';
import operationsRoute from './operations';
import managementRoutes from './management';
import publicRoutes from './public.route';

export const applicationRouter = [];

export const authenticationRouter = [];

const router = [
  {
    path: 'auth',
    children: authRoutes
  },

  {
    path: 'certificate/:id',
    element: <CertificateLayout />,
    children: privateCertificateRoutes
  },
  {
    path: 'public',
    children: publicRoutes
  },
  {
    path: 'certificate/:id',
    element: <CertificateLayout />,
    children: privateCertificateRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: statusRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        children: dashboardsRoutes
      },
      {
        path: 'account/wallet',
        element: <BaseLayout />,
        children: [{ path: '/', element: <WalletView /> }]
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/management',
        children: managementRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/reports',
        children: reportsRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/view-operations',
        children: operationsRoute
      }
    ]
  }
];

export default router;

export const filterRouter = (children, filter) => {
  return (
    children
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item) => {
        if (item.children) item.children = filterRouter(item.children, filter);
        if (filter(item.id)) return item;
      })
      .filter(Boolean)
  );
};
