import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const MonthlySummaryModule = new Module({
  id: 'report-summary',
  name: 'Summary',
  path: 'monthly-summary',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/MonthlySummary'))
  )
});
