import { FILE_UPLOAD_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class FileService extends ApiService {
  constructor() {
    super(FILE_UPLOAD_URL);
  }
}

export default new FileService();
