import React, { useCallback, useEffect, useState } from 'react';
import BrandTable from 'src/components/BrandTable';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
// import Action from 'src/config/models/Action';
import useRefMounted from 'src/hooks/useRefMounted';
import DateTimeService from 'src/utils/DateTimeService';
import { useNavigate } from 'react-router';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useAuth from 'src/hooks/useAuth';
import SimActivationService from 'src/services/SimActivation.service';
import Action from 'src/config/models/Action';
import { SimActivationEditModule, SimActivationViewModule } from '../modules';
import ChangeStatusModal from './ChangeStatusModal';

const daysToYearsMonthsDays = (days) => {
  const years = Math.floor(days / 365);
  days -= years * 365;
  const months = Math.floor((days % 365) / 30);
  days -= months * 30;
  const remainingDays = days % 30;
  let result = '';

  if (years > 0) {
    result += `${years} year(s) `;
  }
  if (months > 0) {
    result += `${months} month(s) `;
  }
  if (remainingDays > 0) {
    result += `${remainingDays} day(s)`;
  }
  return result.trim();
};

function ResultTable({ filters }) {
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const [simActivationOrders, setSimActivationOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState();
  const { user } = useAuth();

  const tableConfig = [
    {
      Header: 'Imei',
      accessor: 'imei'
    },

    {
      Header: 'UID',
      accessor: 'UID'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Duration',
      accessor: ({ durationInDays }) =>
        daysToYearsMonthsDays(durationInDays ?? 0)
    }
  ];

  const getSimActivationOrder = useCallback(async () => {
    setLoading(true);
    try {
      const response = await SimActivationService.get({
        ...filters
      });

      if (isMountedRef.current) {
        setSimActivationOrders(response.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [isMountedRef, filters]);

  useEffect(() => {
    getSimActivationOrder();
  }, [getSimActivationOrder]);

  return (
    <div>
      <ChangeStatusModal
        getData={getSimActivationOrder}
        statusModalOpen={statusModalOpen}
        setStatusModalOpen={setStatusModalOpen}
      />

      <BrandTable
        tableConfig={tableConfig}
        tableData={simActivationOrders}
        filterables={['name', 'description']}
        searchPlaceholder="Search Plans with name..."
        noResultsMessage="No Plans data for the applied filters..."
        loading={loading}
        actions={[
          Action.register(SimActivationViewModule.actions.changeStatus)(
            ({ id, status, item = {} }) => {
              setStatusModalOpen({
                id,
                status,
                simDetails: item?.attributes?.simDetails
              });
            }
          ).withMeta({
            icon: <ChangeCircleIcon />,
            shouldHide: ({ manufacturer }) => {
              return !(
                user.role === 'SUPER_ADMIN' ||
                user.organisation === manufacturer
              );
            },
            shouldDisable: ({ status }) =>
              status === 'COMPLETED' || status === 'REJECTED'
          }),
          Action.register(SimActivationEditModule.actions.edit)(
            ({ device }) => {
              navigate(`/sim-activation-order/edit/${device}`);
            }
          ).withMeta({
            icon: <EditRoundedIcon color="warning" fontSize="small" />,
            shouldHide: ({ manufacturer }) => {
              return !(
                user.role === 'SUPER_ADMIN' ||
                user.organisation === manufacturer
              );
            },
            shouldDisable: ({ status }) =>
              status === 'COMPLETED' || status === 'REJECTED'
          })
        ]}
      />
    </div>
  );
}

export default ResultTable;
