import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const CollectionModule = new Module({
  id: 'collection-summary',
  name: 'Collection',
  path: 'collection',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/CollectionReport'))
  )
});
