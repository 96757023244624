import React from 'react';
import { SimActivationApprovalStatusType } from 'src/enums';
import BrandAutocomplete, { createOption } from '.';

function SimActivationApprovalStatusAutocomplete({ onChange, value, name }) {
  return (
    <BrandAutocomplete
      label="Approval Status"
      placeholder="Select sim activation approval status..."
      onChange={onChange}
      value={value}
      name={name ?? 'simActivationApprovalStatus'}
      options={SimActivationApprovalStatusType.map((el) =>
        createOption(el, el)
      )}
    />
  );
}

export default SimActivationApprovalStatusAutocomplete;
