import { DEVICE_URL } from 'src/config/api.config';
import { ITEM_URL } from 'src/config/erp.api.config';
import ApiService from './Api.service';

class DeviceService extends ApiService {
  constructor() {
    super(DEVICE_URL);
  }

  getDeviceAllDetails = async (searchTerm) => {
    return this.doGet(`${DEVICE_URL}/details/${searchTerm}`, {
      ...this._headers
    });
  };

  getMultipleDevices = async (query = {}) => {
    return this.doGet(`${DEVICE_URL}/multiple`, query, {
      ...this._headers
    });
  };

  allocateDevices = async (payload) => {
    return this.doPut(`${DEVICE_URL}/allocate`, payload, null, {
      ...this._headers
    });
  };

  rollbackDevices = async (payload) => {
    return this.doPut(`${DEVICE_URL}/rollback`, payload, null, {
      ...this._headers
    });
  };

  rollbackDevices = async (payload) => {
    return this.doPut(`${DEVICE_URL}/rollback`, payload, null, {
      ...this._headers
    });
  };

  updateDeviceInERP = async (imei, payload) => {
    return this.doPut(`${ITEM_URL}/${imei}`, payload, null, {
      ...this._headers
    });
  };

  getGroupSpecificData = async (params) => {
    return this.doGet(
      `${DEVICE_URL}/get/group`,
      { ...params },
      {
        ...this._headers
      }
    );
  };

  createBulkDevices = async (payload) => {
    return this.doPost(`${DEVICE_URL}/bulk`, payload, null, {
      ...this._headers
    });
  };
}

export default new DeviceService();
