import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const routeViewModule = new Module({
  id: 'route',
  name: 'Route',
  label: 'Route',
  path: 'route',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Routes/View'))
  ),
  actions: {
    edit: {
      id: 'route-edit-result-table',
      name: 'route edit',
      label: 'Edit'
    },
    delete: {
      id: 'route-delete-result-table',
      name: 'route delete',
      label: 'Delete'
    }
  }
});

export const routeAddModule = new Module({
  id: 'route-add',
  name: 'Route Add',
  path: 'route/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Routes/RoutePlanner'))
  ),
  actions: {
    add: {
      id: 'route-add',
      name: 'route Add',
      label: 'Add route'
    }
  }
});

export const routeAddKMLModule = new Module({
  id: 'route-add-kml',
  name: 'Route Add KML',
  path: 'route/add-kml',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Routes/RoutePlannerFromKML'))
  )
  // actions: {
  //   add: {
  //     id: 'route-add',
  //     name: 'route Add',
  //     label: 'Add route'
  //   }
  // }
});

export const routeEditModule = new Module({
  id: 'route-edit',
  name: 'Route Edit',
  path: 'route/edit',
  pathDef: 'route/edit/:id',
  Component: ComponentLoader(
    // lazy(() => import('src/content/management/Routes/Form'))
    lazy(() => import('src/content/management/Routes/RoutePlanner'))
  )
});

const routeModules = [
  routeViewModule,
  routeAddModule,
  routeEditModule,
  routeAddKMLModule
];

export default new ModuleBundle('routes', routeModules);
