import { Card, Grid } from '@mui/material';
import React, { useState } from 'react';
import SimCardIcon from '@mui/icons-material/SimCard';

import PageHeader from 'src/components/PageHeader';
import useQueryParams from 'src/hooks/useQueryParams';

import Filter from './Filter';
import ResultTable from './ResultTable';

function SimActivationOrder() {
  const [params] = useQueryParams();
  const [filters, setFilters] = useState(params);

  return (
    <>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <PageHeader
          heading="Sim Activation Order"
          subtitle="Sim Activation Order"
          Icon={SimCardIcon}
          addRoute="add"
        />

        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <Card>
            <Filter setFilters={setFilters} filter={filters} />
          </Card>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Card>
            <ResultTable filters={filters} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default SimActivationOrder;
