import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const AlertReportModule = new Module({
  id: 'report-alert',
  name: 'Alerts',
  path: 'alert',
  Component: ComponentLoader(lazy(() => import('src/content/reports/Alert')))
});
