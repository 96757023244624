import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cleanObject from 'src/utils/cleanObject';

export default function useQueryParams(defaultValues = {}) {
  const navigate = useNavigate();
  const location = useLocation();
  let search = location.search.slice(1);

  const currentParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  );

  const mergedParams = useMemo(
    () => cleanObject({ ...defaultValues, ...currentParams }),
    [currentParams, defaultValues]
  );

  useEffect(() => {
    const paramsToUpdate = { ...defaultValues, ...currentParams };
    const newSearch = new URLSearchParams(
      cleanObject(paramsToUpdate)
    ).toString();

    if (newSearch !== search) {
      navigate(
        { pathname: location.pathname, search: `?${newSearch}` },
        { replace: true }
      );
    }
  }, [defaultValues, currentParams, location.pathname, navigate, search]);

  const setQueryParams = (params) => {
    search = new URLSearchParams(cleanObject(params)).toString();
    navigate({ pathname: location.pathname, search: search && `?${search}` });
  };

  return [mergedParams, setQueryParams];
}
