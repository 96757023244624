import React from 'react';

import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';

function VehicleResults({ vehicles }) {
  const theme = useTheme();

  return (
    <Box px={2} py={1} key="Vehicles">
      <Typography
        sx={{
          py: 1
        }}
        variant="h5"
      >
        Vehicles
      </Typography>
      {!vehicles.length && (
        <Typography
          fontSize={18}
          fontWeight={300}
          color="grey"
          variant="body2"
          alignItems="center"
          display="flex"
          p={2}
        >
          <DirectionsBusFilledOutlinedIcon
            sx={{
              mr: 3
            }}
            color="disabled"
          />
          No Vehicles for the query
        </Typography>
      )}
      {vehicles.map((vehicle) => (
        <>
          <ListItem sx={{ py: 2 }}>
            <ListItemIcon>
              <DirectionsBusFilledOutlinedIcon
                sx={{
                  mr: 1
                }}
                fontSize="small"
                color={vehicle.isTrackingActivated ? 'success' : 'error'}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    }}
                  >
                    {vehicle.name}
                  </Typography>
                  <Typography variant="h5">
                    {vehicle.registrationNumber}
                  </Typography>
                </Stack>
              }
              secondary={
                <Stack direction="row" sx={{ pt: 1 }}>
                  {vehicle.imei}
                  <Box px={2}>•</Box>
                  {`${vehicle.owner?.firstName} ${vehicle.owner?.lastName}`}
                  <Box px={2}>•</Box>
                  {vehicle.organisation?.brandName}
                </Stack>
              }
            />
            <Divider />
          </ListItem>
        </>
      ))}
    </Box>
  );
}

export default VehicleResults;
