const id = 'traxcity';
const fullName = 'TraxCity';
const name = 'TraxCity';
const logo = 'src/assets/images/traxcity.png';

const loginPageText = `
TraxCity is a leading provider of innovative solutions for smart cities, specializing in cutting-edge technologies that enhance urban living. Our integrated approach combines advanced infrastructure, data-driven insights, and sustainable practices to create efficient, resilient, and interconnected urban environments. From transportation and energy management to healthcare and citizen engagement, we empower cities to thrive in the digital age
`;

const site = 'https://traxcity.in';

const thisSite = 'https://cloud.traxsmart.in/';

const prodUrl = 'https://swm-api.traxsmart.in/v1';

const locationApiKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoidHJheHNtYXJ0IiwiaWF0IjoxNzE0NTk0NDQ3fQ.Ao-pK_KhMT5WbV1hOBbNiKhM94G3i1Z7hUWEvoNv0MA';

const pallete = {
  primary: '#00997D',
  secondary: '#01BAEF',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#08B092'
};

const config = {
  id,
  fullName,
  name,
  loginPageText,
  pallete,
  logo,
  site,
  prodUrl,
  thisSite,
  locationApiKey
};

export default config;
