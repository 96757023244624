import { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';

function SuspenseLoader({ total, current }) {
  const theme = useTheme();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999999999999
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.2),
          backdropFilter: 'blur(10px)'
        }}
      >
        <CardContent p={2} px={6}>
          <Stack direction="row" spacing={4} alignItems="center">
            <CircularProgress
              size={64}
              disableShrink
              thickness={3}
              value={(current * 100) / total}
              variant={total ? 'determinate' : 'indeterminate'}
            />

            {Boolean(current) && (
              <Typography variant="h3">{`${Math.round(
                (current * 100) / total
              )}%`}</Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SuspenseLoader;
