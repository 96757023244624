import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const AdminDivisionViewModule = new Module({
  id: 'admin-division',
  name: 'Admin Division',
  label: 'Admin Division',
  path: 'admin-division',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/AdminDivision/View'))
  ),
  actions: {
    edit: {
      id: 'edit-admin-division-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-admin-division-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const AdminDivisionEditModule = new Module({
  id: 'admin-division-edit',
  name: 'AdminDivisionEdit',
  path: 'admin-division/edit',
  pathDef: 'admin-division/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/AdminDivision/Form/index'))
  )
});

export const AdminDivisionAddModule = new Module({
  id: 'admin-division-add',
  name: 'AdminDivisionAdd',
  path: 'admin-division/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/AdminDivision/Form/index'))
  ),
  actions: {
    add: {
      id: 'admin-division-add',
      name: 'Admin Division Add',
      label: 'Add Admin Division'
    }
  }
});

const AdminDivisionModules = [
  AdminDivisionViewModule,
  AdminDivisionEditModule,
  AdminDivisionAddModule
];

export default new ModuleBundle('AdminDivision', AdminDivisionModules);
