import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const ActivationViewModule = new Module({
  id: 'fitment',
  name: 'Fitment',
  path: 'fitment',
  Component: ComponentLoader(
    lazy(() => import('src/content/root/Activation/View/ResultTable'))
  ),
  actions: {
    ChangeStatus: {
      id: 'approve-activation',
      name: 'approveAtivation',
      label: 'Approve Activation'
    },
    UndoFitment: {
      id: 'undo-activation',
      name: 'undoAtivation',
      label: 'Undo Activation'
    },
    generateCertificate: {
      id: 'generate-certificate',
      name: 'generate-vehicleStatus-certificate',
      label: 'Generate Certificate'
    },
    editCertificate: {
      id: 'edit-certificate',
      name: 'edit-vehicleStatus-certificate',
      label: 'Edit Certificate'
    },
    viewCertificate: {
      id: 'view-certificate',
      name: 'view-vehicleStatus-certificate',
      label: 'View Certificate'
    }
  }
});

export const ActivationAddModule = new Module({
  id: 'fitment-add',
  name: 'FitmentAdd',
  path: 'add',
  Component: ComponentLoader(
    lazy(() => import('src/content/root/Activation/Form'))
  ),
  actions: {
    add: {
      id: 'add-fitment',
      name: 'add-fitment',
      label: 'Add Fitment'
    }
  }
});

export const ActivationEditModule = new Module({
  id: 'activation-edit',
  name: 'ActivationEdit',
  path: '/fitment/edit',
  pathDef: 'fitment/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/root/Activation/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-activation-result-table',
      name: 'edit-activation-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-activation-result-table',
      name: 'delete-activation-result-table',
      label: 'Delete'
    }
  }
});

const ActivationModules = [
  ActivationViewModule,
  ActivationEditModule,
  ActivationAddModule
];

export default new ModuleBundle('Activation', ActivationModules);
