import React from 'react';
import BrandAutocomplete from '.';

function EnumAutocomplete({
  onChange,
  value,
  name,
  label,
  placeholder,
  ENUM = [],
  required = false,
  ...rest
}) {
  const options = ENUM.map((option) => ({
    label: option,
    value: option
  }));

  return (
    <BrandAutocomplete
      label={label}
      placeholder={placeholder ?? 'Select value...'}
      onChange={onChange}
      value={value}
      required={required}
      name={name ?? 'enumSelect'}
      options={options}
      {...rest}
    />
  );
}

export default EnumAutocomplete;
