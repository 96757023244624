import { useContext, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import {
  Box,
  Button,
  Drawer,
  Icon,
  IconButton,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';

import Logo from 'src/components/LogoSign';
import SidebarMenu from './SidebarMenu';
import SidebarTopSection from './SidebarTopSection';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  // <SidebarWrapper
  //       sx={{
  //         display: {
  //           xs: 'none',
  //           lg: 'inline-block'
  //         },
  //         position: 'fixed',
  //         left: 0,
  //         top: 0
  //       }}
  //     >
  //       <Scrollbar>
  //         <TopSection>
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               justifyContent: 'between',
  //               alignItems: 'center',
  //               width: '100%',
  //               mt: 2,
  //               mb: 3
  //             }}
  //           >
  //             <div>
  //               <Logo />
  //             </div>

  //             {/* <Icon
  //               sx={{ marginLeft: 'auto' }}
  //               onClick={handleToggle}
  //               color="primary"
  //             >
  //               keyboard_double_arrow_left
  //             </Icon> */}
  //           </Box>
  //           <SidebarTopSection />
  //         </TopSection>
  //         <SidebarMenu />
  //       </Scrollbar>
  //     </SidebarWrapper>

  return (
    <>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box display="flex" alignItems="center">
                <Box
                  component="span"
                  // sx={{
                  //   display: { lg: 'none', xs: 'inline-block' }
                  // }}
                >
                  <Tooltip arrow title="Toggle Menu">
                    <IconButton color="primary" onClick={toggleSidebar}>
                      <CloseTwoToneIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* <Box
                  component="span"
                  sx={{
                    mr: 1
                    // display: { lg: 'none', xs: 'inline-block' }
                  }}
                >
                  <Logo />
                </Box> */}
                {/* <HeaderSearch /> */}
              </Box>
              <SidebarTopSection />
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>

      {/* Add this style block to your CSS */}
      <style>{`
        .content-container.full-width {
          width: 100%;
        }
      `}</style>
    </>
  );
}

export default Sidebar;
