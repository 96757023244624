import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const SimActivationViewModule = new Module({
  id: 'simActivationOrder-view',
  name: 'Sim Activation Order',
  label: 'Sim Activation Order',
  path: 'sim-activation-order',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/simActivationOrder/View'))
  ),
  actions: {
    changeStatus: {
      id: 'change-status-result-table',
      name: 'changeStatus',
      label: 'Change Status'
    },

    delete: {
      id: 'delete-simActivationOrder-result-table',
      name: 'delete',
      label: 'Delete'
    }
  }
});

export const SimActivationAddModule = new Module({
  id: 'simActivationOrder-add',
  name: 'SimActivationAdd',
  path: 'sim-activation-order/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/simActivationOrder/Form'))
  ),
  actions: {
    add: {
      id: 'add-simActivationOrder',
      name: 'add-simActivationOrder',
      label: 'Add Sim Activation Order'
    }
  }
});

export const SimActivationEditModule = new Module({
  id: 'simActivationOrder-edit',
  name: 'SimActivationEdit',
  path: 'sim-activation-order/edit',
  pathDef: 'sim-activation-order/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/simActivationOrder/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-simActivationOrder-result-table',
      name: 'edit-simActivationOrder-result-table',
      label: 'Edit'
    }
  }
});

const SimActivationOrderModules = [
  SimActivationViewModule,
  SimActivationAddModule,
  SimActivationEditModule
];

export default SimActivationOrderModules;
