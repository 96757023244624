import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';

import SuspenseLoader from 'src/components/SuspenseLoader';
import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import FSLiveTrackingModules from 'src/content/FSLiveTracking/module';
import SimActivationOrderForm from 'src/content/management/simActivationOrder/Form';
import SimActivationOrder from 'src/content/management/simActivationOrder/View';
import ActivationForm from 'src/content/root/Activation/Form';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AIS140 = Loader(lazy(() => import('src/content/dashboards/AIS140')));
const Dashboard = Loader(
  lazy(() => import('src/content/dashboards/DashboardV2'))
);
const FitmentRecords = Loader(
  lazy(() => import('src/content/management/FitmentRecords/View'))
);
const VehicleStatusDashboard = Loader(
  lazy(() => import('src/content/dashboards/VehicleStatus'))
);
const Activation = Loader(
  lazy(() => import('src/content/root/Activation/View'))
);
const AddActivation = Loader(
  lazy(() => import('src/content/root/Activation/Form'))
);

// const TermsOfUse = Loader(lazy(() => import('src/TermsOfUse')));
// const PrivacyPage = Loader(lazy(() => import('src/PrivacyPage')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="/vehicle-status" />
  },
  {
    path: '/dashboard',
    element: <AIS140 />
  },
  {
    path: '/dashboardv2',
    element: <Dashboard />
  },
  // {
  //   path: '/fitmentRecords',
  //   element: <FitmentRecords />
  // },
  {
    path: '/vehicle-status',
    element: <VehicleStatusDashboard />
  },
  registerModulesWithRouter(FSLiveTrackingModules),

  // {
  //   path: '/term-of-use',
  //   element: <TermsOfUse />
  // },
  // {
  //   path: '/privacy',
  //   element: <PrivacyPage />
  // },
  {
    path: '/fitment',
    element: <Activation />
  },
  {
    path: '/fitment/add',
    element: <AddActivation />
  },
  {
    path: '/fitment/edit/:id',
    element: <ActivationForm />
  },
  {
    path: 'sim-activation-order',
    element: <SimActivationOrder />
  },
  {
    path: 'sim-activation-order/add',
    element: <SimActivationOrderForm />
  },
  {
    path: 'sim-activation-order/edit/:id',
    element: <SimActivationOrderForm />
  }
];

export default dashboardsRoutes;
