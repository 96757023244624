/* eslint-disable camelcase */
import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  CircularProgress,
  // Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Zoom,
  useMediaQuery
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import BrandInput from 'src/components/FormInputs/BrandInput';
import SimActivationOrderStatusAutoComplete from 'src/components/FormInputs/BrandAutocomplete/SimActivationOrderStatusAutoComplete';
import SubSection from 'src/components/SubSection';
import useAuth from 'src/hooks/useAuth';
import ESimAutoComplete from 'src/components/FormInputs/BrandAutocomplete/ESimAutoComplete';
import SimActivationService from 'src/services/SimActivation.service';

function ChangeStatusModal({ statusModalOpen, setStatusModalOpen, getData }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const closeModal = () => {
    setStatusModalOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!statusModalOpen}
      onClose={closeModal}
    >
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          Change Status
        </Typography>
        <Typography variant="subtitle2">
          Use this modal dialog to change the status of sim activation.
        </Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const {
              status,
              sim1,
              sim2,
              sim1_operator,
              sim2_operator,
              eSimProvider
            } = values;

            if (!status) {
              throw new Error('Status is required');
            }

            if (
              status === 'DONE' &&
              (!sim1 ||
                !sim2 ||
                !sim1_operator ||
                !sim2_operator ||
                !eSimProvider)
            )
              throw new Error(
                'SIM OPERATORs,E-SIM Provider and MSISDNs are required'
              );

            let payload = values;

            if (status === 'DONE') {
              payload = {
                ...payload,
                isSimActivated: true,
                simActivationApprovedBy: user.id,
                simActivationDate: Date.now()
              };
            }

            const { success, message } = await SimActivationService.put(
              statusModalOpen?.id,
              payload
            );

            enqueueSnackbar(message, {
              variant: success ? 'success' : 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              TransitionComponent: Zoom
            });
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            closeModal();
            getData();
          } catch (err) {
            if (!err) return;
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              TransitionComponent: Zoom
            });
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3}>
                <SimActivationOrderStatusAutoComplete
                  onChange={setFieldValue}
                  name="status"
                  value={values.status}
                />

                {values.status === 'DONE' && (
                  <SubSection heading="Enter Sim Deatils">
                    <ESimAutoComplete
                      md={12}
                      onChange={setFieldValue}
                      name="eSimProvider"
                      value={values.eSimProvider}
                    />
                    <BrandInput
                      md={6}
                      label="sim1 operator"
                      name="sim1_operator"
                      onChange={handleChange}
                      value={values.sim1_operator}
                      type="textbox"
                      placeholder="Enter SIM operator 1.."
                    />
                    <BrandInput
                      md={6}
                      label="sim1 msisdn"
                      name="sim1"
                      onChange={handleChange}
                      value={values.sim1}
                      type="textbox"
                      placeholder="Enter SIM1 MSISDN.."
                    />
                    <BrandInput
                      md={6}
                      label="sim2 operator"
                      name="sim2_operator"
                      onChange={handleChange}
                      value={values.sim2_operator}
                      type="textbox"
                      placeholder="Enter SIM operator 2.."
                    />
                    <BrandInput
                      md={6}
                      label="sim2"
                      name="sim2"
                      onChange={handleChange}
                      value={values.sim2}
                      type="textbox"
                      placeholder="Enter SIM2 MSISDN.."
                    />
                  </SubSection>
                )}
              </Grid>
            </DialogContent>
            <Box
              sx={{
                display: { xs: 'block', sm: 'flex' },
                alignItems: 'center',
                justifyContent: 'flex-end',
                p: 3
              }}
            >
              <Box>
                <Button
                  fullWidth={mobile}
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ChangeStatusModal;
