import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const RouteViolationModule = new Module({
  id: 'Route-violation',
  name: 'Route Violation',
  path: 'route-violation',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/RouteViolation'))
  )
});
