import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import FSLiveTrackingModules from 'src/content/FSLiveTracking/module';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const RoutePlayback = Loader(lazy(() => import('src/content/RoutePlayback')));
const LiveTracking = Loader(lazy(() => import('src/content/LiveTracking')));

const dashboardsRoutes = [
  {
    path: '/live-tracking',
    element: <LiveTracking />
  },
  {
    path: '/route-playback',
    element: <RoutePlayback />
  },
  registerModulesWithRouter(FSLiveTrackingModules)
];

export default dashboardsRoutes;
