import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const SosAlertModule = new Module({
  id: 'report-sosalert',
  name: 'SOS Alerts',
  path: 'sosalert',
  Component: ComponentLoader(lazy(() => import('src/content/reports/SosAlert')))
});
