import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/lab';
import { Grid, TextField, Box, IconButton } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export function formikDateSetter(name, setter) {
  return (value) => setter?.(name, new Date(value).toISOString());
}

function BrandDatePicker({
  xs,
  md,
  placeholder,
  value,
  onChange,
  label,
  name,
  maxDate,
  minDate,
  required = false // Add required prop with default value false
}) {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    formikDateSetter(name, value && new Date(value));
  }, []);

  return (
    <Grid item xs={xs || 12} md={md || 6}>
      <Box pb={1}>
        <b>
          {label}
          {required && <span style={{ color: 'red' }}> *</span>}
        </b>
      </Box>
      <DatePicker
        value={value && new Date(value)}
        onChange={formikDateSetter(name, onChange)}
        renderInput={(params) => (
          <TextField
            fullWidth
            placeholder={placeholder}
            {...params}
            InputProps={{
              ...params?.InputProps,
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false),
              endAdornment: (
                <>
                  {isHovered && value && (
                    <IconButton
                      sx={{
                        borderRadius: '12px',
                        bgcolor: 'rgb(255, 220, 226)',
                        height: '29px',
                        width: '29px'
                      }}
                      onClick={() => onChange(null)}
                      size="small"
                      color="error"
                    >
                      <ClearOutlinedIcon />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        maxDate={maxDate ?? new Date()}
        minDate={minDate}
        inputFormat="dd/MM/yyyy"
      />
    </Grid>
  );
}

export default BrandDatePicker;
