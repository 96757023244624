import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DeviceModelViewModule = new Module({
  id: 'device-model',
  name: 'Device Model',
  label: 'Device Model',
  path: 'device-model',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/DeviceModel/View'))
  ),
  actions: {
    edit: {
      id: 'edit-device-model-result-table',
      name: 'edit',
      label: 'Edit'
    },
    delete: {
      id: 'delete-device-model-result-table',
      name: 'delete',
      label: 'Edit'
    }
  }
});

export const DeviceModelEditModule = new Module({
  id: 'device-model-edit',
  name: 'DeviceModelEdit',
  path: 'device-model/edit',
  pathDef: 'device-model/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/DeviceModel/Form'))
  )
});

export const DeviceModelAddModule = new Module({
  id: 'device-model-add',
  name: 'DeviceModelAdd',
  path: 'device-model/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/DeviceModel/Form'))
  ),
  actions: {
    add: {
      id: 'device-model-add',
      name: 'DeviceModel Add',
      label: 'Add Device Model'
    }
  }
});

const DeviceModelModules = [
  DeviceModelViewModule,
  DeviceModelEditModule,
  DeviceModelAddModule
];

export default new ModuleBundle('DeviceModel', DeviceModelModules);
