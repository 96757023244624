import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileManagerSidebar from 'src/content/applications/FileManager/FileManagerSidebar';

function ImageUploader({
  maxFiles,
  minFiles,
  files,
  setFiles,
  folder = 'IMAGE'
}) {
  const [isUploaderOpen, setUploaderOpen] = useState(null);

  const openFileUploader = () => setUploaderOpen(true);
  const closeFileUploader = () => setUploaderOpen(null);

  return (
    <>
      <Dialog open={isUploaderOpen} onClose={closeFileUploader} color="black">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <Typography variant="h4">Upload Document</Typography>

            <Button
              sx={{
                marginLeft: 'auto',
                marginTop: '0.5rem',
                marginRight: '0.5rem'
              }}
              variant="outlined"
              color="error"
              onClick={closeFileUploader}
            >
              <CloseIcon />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            <FileManagerSidebar
              setFiles={(arr) => {
                setFiles(arr);
                closeFileUploader();
              }}
              accept="image/jpeg, image/png"
              maxFiles={maxFiles}
              minFiles={minFiles}
              folder={folder}
            />
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack direction="row" spacing={3}>
        {Array(maxFiles)
          .fill(0)
          .map((_, idx) => (
            <Card
              key={idx}
              onClick={openFileUploader}
              sx={{
                height: '150px',
                aspectRatio: '1',
                width: 'auto',
                // backgroundSize: files?.[idx]?.preview ? 'cover' : 'contain',
                backgroundSize: 'cover',
                backgroundImage: `url("${
                  files?.[idx]?.preview ??
                  'https://cdn3d.iconscout.com/3d/premium/thumb/upload-image-8079452-6458695.png'
                }" )`
              }}
            />
          ))}
      </Stack>
    </>
  );
}

export default ImageUploader;
