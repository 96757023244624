import React, { useEffect, useState } from 'react';
import RTOService from 'src/services/RTO.service';
import BrandAutocomplete, { createOption, createOptionsFromArr } from '.';

// TODO: data will come from api
function RTOAutoComplete({
  onChange,
  value,
  name,
  label,
  state,
  required = false,
  ...rest
}) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    (async () => {
      if (!state) return;
      setOptions([]);
      const { data } = await RTOService.get({ state });
      Object.keys(data?.no || {})?.forEach((key) => {
        let name = data?.no?.[key];
        setOptions((prev) => [
          ...prev,
          {
            value: `${name}-${state}_${key}`,
            label: `${name} (${state}_${key})`
          }
        ]);
      });
    })();
  }, [state]);

  return (
    <BrandAutocomplete
      label={label}
      placeholder="Select RTO"
      onChange={onChange}
      required={required}
      value={value}
      name={name ?? 'RTO'}
      options={options}
      {...rest}
    />
  );
}

export default RTOAutoComplete;
