import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';

export const VehicleTimelineModule = new Module({
  id: 'report-vehicle-timeline',
  name: 'Vehicle Timeline',
  path: 'vehicle-timeline',
  Component: ComponentLoader(
    lazy(() => import('src/content/reports/VehicleTimeline'))
  )
});
